import ReconnectingWebSocket from './reconnect_websocket';

document.addEventListener('DOMContentLoaded', function () {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  const domain =
    protocol == 'ws:' ? '127.0.0.1:8000' : window.location.hostname;
  const notificationContainer = document.getElementById('notification-list');

  const notificationWebsocketURL = `${protocol}//${domain}/ws/notification/`;

  const notificationSocket = new ReconnectingWebSocket(
    notificationWebsocketURL,
  );

  notificationSocket.addEventListener('open', (event) => {});

  notificationSocket.addEventListener('message', (event) => {
    if (notificationContainer) {
      document.getElementById('refresh-button')?.click();
    }

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      showConfirmButton: false,
      iconColor: 'white',
      customClass: {
        popup: 'colored-toast',
      },
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    let data = JSON.parse(event.data);

    Toast.fire({
      icon: data.attention ? 'warning' : 'info',
      title: data.attention
        ? 'Your attention is required'
        : 'You have a new notification',
    });

    if (data.attention) {
      // alert the admin for prohibited information in messages.
      const sosSound = document.getElementById('sosSound');
      const maxPlays = 3;
      let playCount = 0;
      if (sosSound) {
        sosSound.play();
        sosSound.onended = function () {
          playCount++;
          if (playCount < maxPlays) {
            sosSound.play();
          }
        };
      }
    } else {
      document.getElementById('notificationSound')?.play();
    }
  });
});
