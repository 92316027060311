document.addEventListener('DOMContentLoaded', function () {
  const chatForm = document.getElementById('chat-form');
  const chatInput = document.getElementById('chat-input');
  const contractId = document.querySelector('[name=contractid]')?.value;

  chatForm?.addEventListener('submit', async function (e) {
    e.preventDefault();

    const message = chatInput.value.trim();
    if (!message) return;

    const submitBtn = chatForm.querySelector('button[type=submit]');
    submitBtn.disabled = true;

    submitBtn.innerHTML = `<span class="inline-block w-6 h-6 border-2 border-t-2 border-t-white rounded-full animate-spin"></span>`;

    try {
      const response = await fetch(
        `/api/contract/livechat/send-message/?contract_id=${contractId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': document.querySelector('[name=csrfmiddlewaretoken]')
              .value,
          },
          body: JSON.stringify({
            message: message,
          }),
        },
      );

      let data = await response.json();
      if (!response.ok) {
        throw new Error(
          data.error || 'An error occurred while sending the message.',
        );
      } else {
        chatInput.value = '';
      }
    } catch (error) {
      swal.fire({
        title: 'Error',
        text: error,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      submitBtn.disabled = false;
      submitBtn.innerHTML = `<svg class="w-6 h-6 text-white transform rotate-45" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
              </svg>`;
    }
  });
});
