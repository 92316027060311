import ReconnectingWebSocket from './websocket/reconnect_websocket';
import RevolutCheckout from '@revolut/checkout';
import Swal from './sweetalert2_11.js';

document.addEventListener('DOMContentLoaded', function () {
  let modal;
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  const domain =
    protocol == 'ws:' ? '127.0.0.1:8000' : window.location.hostname;

  const transactionWebsocketURL = `${protocol}//${domain}/ws/transaction/`;

  // Create a new WebSocket connection
  const transactionSocket = new ReconnectingWebSocket(transactionWebsocketURL);

  // Handle WebSocket message events
  transactionSocket.addEventListener('message', (event) => {
    let message = event;
    console.log('WebSocket connection opened:', message);
    let data = JSON.parse(message.data);
    displayTransactionStatus(data);
  });

  // Handle checkout button click
  document.getElementById('checkout-button')?.addEventListener('click', (e) => {
    e.preventDefault();

    const button = document.getElementById('checkout-button');
    const loadingBtn = document.getElementById('loading-button');

    // Show diable loading button
    loadingBtn.classList.remove('hidden');
    button.classList.add('hidden');

    // Collect form data
    const formData = {
      first_name: document.querySelector('input[name="first_name"]').value,
      last_name: document.querySelector('input[name="last_name"]').value,
      company_name: document.querySelector('input[name="company_name"]').value,
      email: document.querySelector('input[name="email"]').value,
      mobile: document.querySelector('input[name="mobile"]').value,
      country: document.querySelector('select[name="country"]').value,
      street_address: document.querySelector('input[name="street_address"]')
        .value,
      town_city: document.querySelector('input[name="town_city"]').value,
      postcode_zipcode: document.querySelector('input[name="postcode_zipcode"]')
        .value,
      package_id: document.getElementById('package-id').value,
    };

    fetch('/api/create-payment-link/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': document.querySelector('[name="csrfmiddlewaretoken"]')
          .value,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        handlePaymentProcess(data);
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'An error occurred while processing your request.',
          icon: 'error',
        });
      })
      .finally(() => {
        // Re-enable button and hide loading button
        loadingBtn.classList.add('hidden');
        button.classList.remove('hidden');
      });
  });

  // Handle Upgrade Button Click
  document.getElementById('upgrade-button')?.addEventListener('click', (e) => {
    e.preventDefault();
    const button = document.getElementById('upgrade-button');
    const loadingBtn = document.getElementById('loading-button');

    // Show diable loading button
    loadingBtn.classList.remove('hidden');
    button.classList.add('hidden');

    // Collect form data
    const formData = {
      first_name: document.querySelector('input[name="first_name"]').value,
      last_name: document.querySelector('input[name="last_name"]').value,
      company_name: document.querySelector('input[name="company_name"]').value,
      email: document.querySelector('input[name="email"]').value,
      mobile: document.querySelector('input[name="mobile"]').value,
      country: document.querySelector('select[name="country"]').value,
      street_address: document.querySelector('input[name="street_address"]')
        .value,
      town_city: document.querySelector('input[name="town_city"]').value,
      postcode_zipcode: document.querySelector('input[name="postcode_zipcode"]')
        .value,
      package_id: document.getElementById('package-id').value,
    };

    fetch('/api/upgrade-payment-link/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': document.querySelector('[name="csrfmiddlewaretoken"]')
          .value,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        handlePaymentProcess(data);
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'An error occurred while processing your request.',
          icon: 'error',
        });
      })
      .finally(() => {
        // Re-enable button and hide loading button
        loadingBtn.classList.add('hidden');
        button.classList.remove('hidden');
      });
  });

  function displayTransactionStatus(data) {
    const transactionStatusContainer = document.getElementById(
      'transactionStatusContainer',
    );
    if (typeof data == 'string') {
      data = JSON.parse(data);
    }
    if (!data.ignore_modal && modal) modal.close();

    if (data.status == 'successful') {
      Swal.fire({
        title: 'Payment Successful',
        text: 'Your payment has been confirmed.',
        icon: 'success',
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/dashboard/';
        }
      });
    } else {
      Swal.fire({
        title: 'Payment Failed',
        text: 'Your payment has failed. If you were charged, please contact us immediately for assistance. This may be due to a temporary issue or internet connection problem.',
        icon: 'error',
      });
    }
  }

  function handlePaymentProcess(data) {
    Swal.fire({
      title: '',
      html: `
        <div class="flex flex-col items-center justify-center">
          <div class="relative">
            <div class="w-12 h-12 bg-blue-500 rounded-full animate-ping absolute"></div>
            <div class="w-12 h-12 bg-blue-500 rounded-full relative"></div>
          </div>
          <div class="mt-4 text-gray-700">Processing Payment...</div>
        </div>      `,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (data.success) {
      if (data.is_debug) {
        window.location.href = data.payment_data.redirect_url;
      } else {
        if (data.payment_method === 'flutterwave') {
          // Payment details for Flutterwave
          const paymentData = {
            public_key: data.payment_data.public_key,
            tx_ref: data.payment_data.tx_ref,
            amount: data.payment_data.amount,
            currency: data.payment_data.currency,
            redirect_url: data.payment_data.redirect_url,
            payment_options: data.payment_data.payment_options,
            customer: {
              email: data.payment_data.customer.email,
              phone_number: data.payment_data.customer.phone_number,
              name: data.payment_data.customer.name,
            },
            customizations: {
              title: data.payment_data.customizations.title,
              description: data.payment_data.customizations.description,
              logo: data.payment_data.customizations.logo,
            },
            onclose: function (incomplete) {
              if (incomplete) {
                Swal.fire({
                  title: 'Payment Cancelled',
                  text: 'Your payment has been cancelled.',
                  icon: 'warning',
                });
              }
            },
          };

          // Open Flutterwave popup
          modal = FlutterwaveCheckout(paymentData);
        } else if (data.payment_method === 'revolut') {
          const orderToken = data.payment_data.order_token;
          // Handle payment callback
          RevolutCheckout(orderToken, 'sandbox').then(function (instance) {
            // On click open payment pop-up
            instance.payWithPopup({
              name: data.payment_data.customer.name,
              email: data.payment_data.customer.email,
              phone: data.payment_data.customer.phone_number,
              savePaymentMethodFor: 'customer',
              billingAddress: {
                countryCode: data.payment_data.address.country_code,
                city: data.payment_data.address.city,
                postcode: data.payment_data.address.postcode,
                streetLine1: data.payment_data.address.street,
              },
              onSuccess() {
                // Do something to handle successful payments
                Swal.fire({
                  title: '',
                  html: `
                  <div class="flex flex-col items-center justify-center">
                    <div class="relative">
                      <div class="w-12 h-12 bg-blue-500 rounded-full animate-ping absolute"></div>
                      <div class="w-12 h-12 bg-blue-500 rounded-full relative"></div>
                    </div>
                    <div class="mt-4 text-gray-700">Checking status...</div>
                  </div>      `,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                });

                instance.destroy();
                const linkElem = document.createElement('a');
                linkElem.target = '_blank';
                linkElem.href = data.payment_data.redirect_url;
                linkElem.click();
              },
              onError(error) {
                console.dir(error);
                Swal.fire({
                  title: 'Payment Error',
                  text: `Something went wrong. Please try again.`,
                  icon: 'error',
                });
              },
              onCancel() {
                Swal.fire({
                  title: 'Payment Cancelled',
                  text: 'Your payment has been cancelled.',
                  icon: 'warning',
                });
              },
            });
          });
        }
      }
    } else {
      Swal.fire({
        title: 'Error',
        text: data.message,
        icon: 'warning',
      });
    }
  }
});
