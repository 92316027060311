const slidesContainer = document.querySelector('.slides-container');

if (slidesContainer) {
  $('.slides-container').owlCarousel({
    loop: true,
    nav: false,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    smartSpeed: 450,
    margin: 30,
    dots: true,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      991: {
        items: 2,
      },
      1200: {
        items: 3,
      },
    },
  });
}
