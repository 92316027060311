// Initialize modal
document.addEventListener('DOMContentLoaded', () => {
  let selectedStaffId = null;
  let initialStaffId = null;
  let hasChanges = false;
  const contractId = document.querySelector('[name=contractid]')?.value;
  let permissionData = {};

  async function fetchStaffData() {
    try {
      const response = await fetch('/api/staff/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': document.querySelector('[name=csrfmiddlewaretoken]')
            .value,
        },
        body: JSON.stringify({
          contract_id: contractId,
        }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch staff data');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching staff:', error);
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
      });
      return [];
    }
  }

  async function openModal() {
    const modal = document.getElementById('staffModal');
    const modalContent = document.getElementById('modalContent');
    modal.classList.remove('hidden');
    setTimeout(() => {
      modalContent.classList.remove('scale-95', 'opacity-0');
      modalContent.classList.add('scale-100', 'opacity-100');
    }, 50);

    const staffList = document.getElementById('staffList');
    staffList.innerHTML = `<div class="flex justify-center items-center py-8" id="loadingState"><div class="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div></div>`;

    let staffData = await fetchStaffData();
    const assignedStaff = staffData.find((staff) => staff.assigned);
    if (assignedStaff) {
      selectedStaffId = assignedStaff.id;
      initialStaffId = assignedStaff.id;
    }
    populateStaffList(staffData);
  }

  async function closeModal() {
    if (hasChanges) {
      const result = await Swal.fire({
        title: 'Unsaved Changes',
        text: 'You have unsaved changes. Are you sure you want to close?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, close it!',
      });

      if (!result.isConfirmed) {
        return;
      }
    }

    const modal = document.getElementById('staffModal');
    const modalContent = document.getElementById('modalContent');
    modalContent.classList.remove('scale-100', 'opacity-100');
    modalContent.classList.add('scale-95', 'opacity-0');
    setTimeout(() => {
      modal.classList.add('hidden');
      hasChanges = false;
    }, 300);
  }

  function createStaffElement(staff) {
    const container = document.createElement('div');
    container.className =
      'flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50';

    const leftContainer = document.createElement('div');
    leftContainer.className = 'flex items-center gap-4';

    const img = document.createElement('img');
    img.src = staff.profile_pic;
    img.alt = staff.name;
    img.className = 'w-10 h-10 rounded-full';

    const infoContainer = document.createElement('div');

    const name = document.createElement('h4');
    name.className = 'font-medium';
    name.textContent = staff.name;

    const contracts = document.createElement('p');
    contracts.className = 'text-sm text-gray-500';
    contracts.textContent = `Ongoing Contracts: ${staff.ongoing_contracts}`;

    infoContainer.appendChild(name);
    infoContainer.appendChild(contracts);

    if (staff.assigned) {
      const assignedSpan = document.createElement('span');
      assignedSpan.className = 'text-xs text-green-600';
      assignedSpan.textContent = 'Currently Assigned';
      infoContainer.appendChild(assignedSpan);
    }

    leftContainer.appendChild(img);
    leftContainer.appendChild(infoContainer);

    const label = document.createElement('label');
    label.className = 'relative inline-flex items-center cursor-pointer';

    const input = document.createElement('input');
    input.type = 'checkbox';
    input.className = 'sr-only peer';
    input.checked = staff.id === selectedStaffId;
    input.addEventListener('change', () => handleStaffSelection(staff.id));

    const toggle = document.createElement('div');
    toggle.className =
      "w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600";

    label.appendChild(input);
    label.appendChild(toggle);

    container.appendChild(leftContainer);
    container.appendChild(label);

    return container;
  }

  function populateStaffList(staffData) {
    const staffList = document.getElementById('staffList');
    staffList.innerHTML = '';
    staffData.forEach((staff) => {
      staffList.appendChild(createStaffElement(staff));
    });
  }

  async function handleStaffSelection(staffId) {
    selectedStaffId = selectedStaffId === staffId ? null : staffId;
    hasChanges = selectedStaffId !== initialStaffId;
    const staffData = await fetchStaffData();
    populateStaffList(staffData);
  }

  async function saveAssignments() {
    try {
      const response = await fetch('/api/staff/assign/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': document.querySelector('[name=csrfmiddlewaretoken]')
            .value,
        },
        body: JSON.stringify({
          staff_id: selectedStaffId,
          contract_id: contractId,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        if (!data.detail) {
          throw Error('Failed to save staff assignment. Please try again.');
        }
        throw new Error(data.detail);
      }

      hasChanges = false;

      let assigned_staff = data.assigned_staff;
      if (assigned_staff) {
        await Swal.fire({
          title: 'Success',
          text: data.detail,
          icon: 'success',
        });
        openStaffAssignModalBtn.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg> Reassign Staff`;
      } else {
        await Swal.fire({
          title: 'Attention!',
          text: data.detail,
          icon: 'warning',
        });
        openStaffAssignModalBtn.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg> Assign Staff`;
      }
      closeModal();
    } catch (error) {
      console.error('Error saving assignment:', error);
      Swal.fire({
        title: 'Error',
        text:
          error.message || 'Failed to save staff assignment. Please try again.',
        icon: 'error',
      });
    }
  }

  const openStaffAssignModalBtn = document.getElementById(
    'open_assign_modal_btn',
  );
  const closeStaffAssignModalBtn = document.getElementById('close-modal-btn');
  const saveAssignmentsBtn = document.getElementById('saveAssignmentsBtn');

  openStaffAssignModalBtn?.addEventListener('click', openModal);
  closeStaffAssignModalBtn?.addEventListener('click', closeModal);
  saveAssignmentsBtn?.addEventListener('click', saveAssignments);
  document?.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') closeModal();
  });

  // permission modal code
  const permissionModalBtn = document.getElementById('permissionModalBtn');
  const permissionModal = document.getElementById('permissionModal');
  const permissionModalContent = document.getElementById(
    'permissionModalContent',
  );
  const closePermissionBtn = document.getElementById(
    'close-permission-modal-btn',
  );
  const permissionList = document.getElementById('permissionList');
  const permissionLoadingState = `<div class="flex justify-center items-center py-8" id="permissionLoadingState">
            <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
          </div>`;
  const savePermissionsBtn = document.getElementById('savePermissionsBtn');

  let originalPermissions = [];
  let hasUnsavedChanges = false;

  // Function to open permission modal
  async function openPermissionModal() {
    permissionModal.classList.remove('hidden');
    setTimeout(() => {
      permissionModalContent.classList.remove('scale-95', 'opacity-0');
      permissionModalContent.classList.add('scale-100', 'opacity-100');
      loadPermissions();
    }, 50);
  }

  // Function to close permission modal
  async function closePermissionModal() {
    if (!permissionModal.classList.contains('hidden')) {
      if (hasUnsavedChanges) {
        const result = await Swal.fire({
          title: 'Unsaved Changes',
          text: 'You have unsaved permission changes. Do you want to save them before closing?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Discard',
          showDenyButton: true,
          denyButtonText: 'Cancel',
        });

        if (result.isConfirmed) {
          await savePermissions();
          return;
        } else if (result.isDenied) {
          return;
        }
      }
      permissionModal.classList.add('hidden');
      permissionModalContent.classList.remove('scale-100', 'opacity-100');
      permissionModalContent.classList.add('scale-95', 'opacity-0');
      hasUnsavedChanges = false;
    }
  }

  // Function to check for changes
  function checkForChanges() {
    const currentPermissions = Array.from(
      document.querySelectorAll('#permissionList input[type="checkbox"]'),
    ).map((checkbox) => ({
      code_name: checkbox.dataset.code,
      granted: checkbox.checked,
    }));

    hasUnsavedChanges = originalPermissions.some((original) => {
      const current = currentPermissions.find(
        (curr) => curr.code_name === original.code_name,
      );
      return current && original.granted !== current.granted;
    });
  }

  // Function to load permissions
  async function loadPermissions() {
    try {
      permissionList.innerHTML = permissionLoadingState;
      const response = await fetch('/api/contract/permissions/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': document.querySelector('[name=csrfmiddlewaretoken]')
            .value,
        },
        body: JSON.stringify({
          contract_id: contractId,
        }),
      });

      const permissions = await response.json();

      if (!response.ok) {
        throw new Error(permissions.error || 'Failed to load permissions');
      }

      originalPermissions = permissions.map((p) => ({
        code_name: p.code_name,
        granted: p.granted,
      }));

      permissionList.innerHTML = '';
      permissions.forEach((permission) => {
        const permissionDiv = document.createElement('div');
        permissionDiv.className =
          'flex items-center justify-between p-3 bg-gray-50 rounded-lg';

        const contentDiv = document.createElement('div');

        const heading = document.createElement('h4');
        heading.className = 'font-medium text-gray-900';
        heading.textContent = permission.name;

        const paragraph = document.createElement('p');
        paragraph.className = 'text-sm text-gray-500';
        paragraph.textContent = permission.code_name;

        contentDiv.appendChild(heading);
        contentDiv.appendChild(paragraph);

        const label = document.createElement('label');
        label.className = 'relative inline-flex items-center cursor-pointer';

        const input = document.createElement('input');
        input.type = 'checkbox';
        input.className = 'sr-only peer';
        input.checked = permission.granted;
        input.dataset.code = permission.code_name;
        input.onchange = checkForChanges;

        const toggleDiv = document.createElement('div');
        toggleDiv.className =
          "w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600";

        label.appendChild(input);
        label.appendChild(toggleDiv);

        permissionDiv.appendChild(contentDiv);
        permissionDiv.appendChild(label);

        permissionList.appendChild(permissionDiv);
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message || 'Failed to load permissions',
        icon: 'error',
      });
    }
  }

  // Function to save permissions
  async function savePermissions() {
    const originalBtnText = savePermissionsBtn.innerHTML;
    try {
      savePermissionsBtn.classList.add('flex');
      savePermissionsBtn.innerHTML =
        '<svg class="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" fill="none"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg> Saving...';
      savePermissionsBtn.disabled = true;

      const updatedPermissions = Array.from(
        document.querySelectorAll('#permissionList input[type="checkbox"]'),
      ).map((checkbox) => ({
        code_name: checkbox.dataset.code,
        granted: checkbox.checked,
      }));

      const response = await fetch('/api/contract/permissions/update/', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': document.querySelector('[name=csrfmiddlewaretoken]')
            .value,
        },
        body: JSON.stringify({
          contract_id: contractId,
          permissions: updatedPermissions,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to save permissions');
      }

      hasUnsavedChanges = false;
      originalPermissions = updatedPermissions;

      const result = await Swal.fire({
        title: 'Success',
        text: 'Permissions updated successfully',
        icon: 'success',
      });
      closePermissionModal();

      if (result.isConfirmed) {
        // Reload the page to reflect changes
        window.location.reload();
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message || 'Failed to save permissions',
        icon: 'error',
      });
    } finally {
      savePermissionsBtn.innerHTML = originalBtnText;
      savePermissionsBtn.disabled = false;
    }
  }

  // Event listeners
  closePermissionBtn?.addEventListener('click', closePermissionModal);
  savePermissionsBtn?.addEventListener('click', savePermissions);

  // Close modal when clicking outside
  permissionModal?.addEventListener('click', (e) => {
    if (e.target === permissionModal) {
      closePermissionModal();
    }
  });

  // Open modal when button is clicked
  permissionModalBtn?.addEventListener('click', openPermissionModal);

  // for updating note

  const noteForm = document.getElementById('noteForm');
  noteForm?.addEventListener('submit', async (e) => {
    e.preventDefault();

    const noteInput = document.getElementById('noteInput').value;

    await fetch('/api/contract/update-note/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': document.querySelector('[name=csrfmiddlewaretoken]')
          .value,
      },
      body: JSON.stringify({
        contract_id: contractId,
        note: noteInput,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to update note');
        }
        return response.json();
      })
      .then((data) => {
        document.getElementById('noteInput').value = data.note;
        Swal.fire({
          title: 'Success',
          text: 'Note updated successfully',
          icon: 'success',
        });
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text:
            error.response?.data?.error ||
            error.message ||
            'Failed to update note',
          icon: 'error',
        });
      });
  });

  // toggle approve btn
  const approveContractBtn = document.getElementById('approveContractBtn');
  const approveContractBtnText = document.getElementById(
    'approveContractBtnText',
  );
  approveContractBtn?.addEventListener('click', async (e) => {
    try {
      let approveClass =
        'bg-green-600 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-700 transition-all duration-300 transform hover:scale-105 flex items-center';
      let unapproveClass =
        'bg-blue-600 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-700 transition-all duration-300 transform hover:scale-105 flex items-center';

      const response = await fetch('/api/contract/approve/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': document.querySelector('[name=csrfmiddlewaretoken]')
            .value,
        },
        body: JSON.stringify({
          contract_id: contractId,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to update approve status');
      }

      let isApproved = data.approved;
      if (isApproved) {
        approveContractBtn.className = approveClass;
        approveContractBtnText.textContent = 'Unapprove Contract';
      } else {
        approveContractBtn.className = unapproveClass;
        approveContractBtnText.textContent = 'Approve Contract';
      }
      await Swal.fire({
        title: 'Success',
        text: isApproved
          ? 'Successfully Approved Contract'
          : 'Successfully Unapprove Contract',
        icon: 'success',
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message || 'Failed to update approve status',
        icon: 'error',
      });
    }
  });

  // handle complete button
  const completeContractBtn = document.getElementById('completeContractBtn');

  completeContractBtn?.addEventListener('click', async (e) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Please confirm that all tasks have been completed and the client is satisfied with the work',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, mark as complete',
      });

      if (result.isConfirmed) {
        const response = await fetch('/api/contract/complete/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': document.querySelector('[name=csrfmiddlewaretoken]')
              .value,
          },
          body: JSON.stringify({
            contract_id: contractId,
          }),
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Failed to update contract status');
        }

        completeContractBtn.textContent = 'Mark as Incomplete';
        completeContractBtn.classList.remove(
          'bg-blue-600',
          'hover:bg-blue-700',
        );
        completeContractBtn.classList.add('bg-red-600', 'hover:bg-red-700');

        const result = await Swal.fire({
          title: 'Success',
          text: 'Contract has been marked as completed successfully',
          icon: 'success',
        });

        if (result.isConfirmed) {
          // Reload the page to reflect changes
          window.location.reload();
        }
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message || 'Failed to update contract status',
        icon: 'error',
      });
    }
  });

  // Handle terminate button
  const terminateContractBtn = document.getElementById('terminateContractBtn');

  terminateContractBtn?.addEventListener('click', async () => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'This action will terminate the contract. This cannot be undone!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, terminate contract',
      });

      if (result.isConfirmed) {
        const response = await fetch('/api/contract/terminate/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': document.querySelector('[name=csrfmiddlewaretoken]')
              .value,
          },
          body: JSON.stringify({
            contract_id: contractId,
          }),
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Failed to terminate contract');
        }

        const result = await Swal.fire({
          title: 'Success',
          text: 'Contract has been terminated successfully',
          icon: 'success',
        });

        if (result.isConfirmed) {
          // Reload the page to reflect changes
          window.location.reload();
        }
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message || 'Failed to terminate contract',
        icon: 'error',
      });
    }
  });
});
