document.addEventListener('DOMContentLoaded', function () {
  const refreshButton = document.getElementById('refresh-button');
  const notificationContainer = document.getElementById('notification-list');
  const refreshImg = document.getElementById('refresh-img');

  refreshButton?.addEventListener('click', function () {
    if (refreshImg.classList.contains('refresh-img')) {
      return;
    }
    refreshButton.disabled = true;
    refreshImg.classList.add('refresh-img');
    fetch('/dashboard/api/notifications/')
      .then((response) => response.text())
      .then((html) => {
        notificationContainer.innerHTML = html;
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Notifications refreshed successfully!',
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong while refreshing notifications!',
        });
      })
      .finally(() => {
        refreshButton.disabled = false;
        refreshImg.classList.remove('refresh-img');
      });
  });

  const notificationsContainer = document.getElementById('notification-list');

  notificationsContainer?.addEventListener('click', function (event) {
    const notificationItem = event.target.closest('.notification-item');
    if (notificationItem) {
      const fullMessage = notificationItem.getAttribute('data-full-msg');
      Swal.fire({
        title: 'Notification',
        text: fullMessage,
        icon: 'info',
        confirmButtonText: 'Close',
      });
    }
  });
});
