document.addEventListener('DOMContentLoaded', function () {
  const searchInput = document.getElementById('contract_search_input');
  const clientTypeFilter = document.getElementById('contract_client_type');
  const contractsContainer = document.getElementById('contract_list_container');

  function applyFilters() {
    const searchQuery = searchInput?.value || '';
    const clientFilter = clientTypeFilter?.value || '';

    fetch(`/api/contracts/?query=${searchQuery}&service=${clientFilter}`)
      .then((response) => response.json())
      .then((contracts) => {
        //Clear the container first
        contractsContainer.innerHTML = '';

        //The dynamically rendered contents goes here below:
        contracts.forEach((contract) => {
          const contractCard = document.createElement('div');
          contractCard.className =
            'col-span-12 md:col-span-6 lg:col-span-4 bg-white p-4 rounded shadow';

          const clientName = document.createElement('h3');
          clientName.className = 'font-bold text-lg text-gray-800';
          clientName.textContent = `Client: ${contract.client_name}`;
          contractCard.appendChild(clientName);

          const serviceName = document.createElement('p');
          serviceName.className = 'text-sm text-gray-600';
          serviceName.textContent = `Service: ${contract.service_name}`;
          contractCard.appendChild(serviceName);

          const planName = document.createElement('p');
          planName.className = 'text-sm text-gray-600';
          planName.textContent = `Plan: ${contract.plan}`;
          contractCard.appendChild(planName);

          const assignedStaff = document.createElement('p');
          assignedStaff.className = 'text-sm text-gray-600';
          assignedStaff.textContent = `Assigned Staff: ${contract.assigned_staff}`;
          contractCard.appendChild(assignedStaff);

          const viewLink = document.createElement('a');
          viewLink.href = contract.contract_url;
          viewLink.className = 'inline-block mt-2 underline text-blue-600';
          viewLink.textContent = 'View Contract';
          contractCard.appendChild(viewLink);

          contractsContainer.appendChild(contractCard);
        });

        //Show a message if no contracts are found
        if (contracts.length === 0) {
          contractsContainer.innerHTML = `
        <div class="col-span-12">
          <div class="flex items-center justify-center">
            <p class="text-lg text-bold text-shadow">
              Sorry, No Content to display
            </p>
          </div>
        </div>
        `;
        }
      })
      .catch((error) => {
        contractsContainer.innerHTML = `
        <div class="col-span-12">
          <div class="flex items-center justify-center">
            <p class="text-lg text-bold text-red-500">
              Failed to load contracts. Please try again later.
            </p>
          </div>
        </div>
        `;
      });
  }

  // Event listeners for filtering
  searchInput?.addEventListener('input', applyFilters);
  clientTypeFilter?.addEventListener('change', applyFilters);

  if (contractsContainer) applyFilters();
});
