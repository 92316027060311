const listOpenBtn = document.getElementById('list_open_btn');
const listCloseBtn = document.getElementById('list_close_btn');
const chatListItems = document.querySelectorAll('.chat_item');

function toggleSidebar() {
  const chatList = document.getElementById('chat_list_bar');
  chatList.classList.toggle('-translate-x-full');
  chatList.classList.toggle('translate-x-0');
}

listOpenBtn?.addEventListener('click', toggleSidebar);
listCloseBtn?.addEventListener('click', toggleSidebar);

chatListItems?.forEach((item) => {
  item?.addEventListener('click', () => {
    chatListItems?.forEach((chat) => chat.classList.remove('bg-blue-50'));
    item.classList.add('bg-blue-50');
  });
});
