document.addEventListener('DOMContentLoaded', function () {
  function handleCheckStatus(target) {
    Swal.fire({
      title: 'Checking Contract Status',
      html: `<div class="flex flex-col items-center justify-center">
            <div class="animate-ping absolute w-32 h-32 rounded-full bg-sky-100"></div>
            <div class="relative w-32 h-32 bg-sky-200 rounded-full animate-pulse flex items-center justify-center">
              <div class="animate-ping absolute h-8 w-8 rounded-full bg-sky-400 opacity-75"></div>
              <div class="relative h-8 w-8 rounded-full bg-sky-500"></div>
            </div>
            <p class="mt-4 text-lg text-gray-700">Please wait while we check your contract status...</p>
          </div>`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });

    const contractStatusContainer = document.getElementById(
      'contract-status-container',
    );
    fetch('/dashboard/api/get-contract-status/')
      .then((response) => response.text())
      .then((html) => {
        if (html.includes('yembot')) {
          window.location.reload();
        }

        contractStatusContainer.innerHTML = html;
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Contract state has been updated!',
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong while refreshing contract state!',
        });
      });
  }

  document.addEventListener('click', function (event) {
    const target = event.target;

    target.closest('#check-status-btn') && handleCheckStatus(target);
  });
});
