import { getDocument, GlobalWorkerOptions } from './pdfjs/pdf.mjs';

GlobalWorkerOptions.workerSrc = './pdfjs/pdf.worker.mjs';

const container = document.getElementById('pdf_container');
const canvas = document.getElementById('pdf_viewer');
const pdfUrl = canvas?.getAttribute('data-pdf-url');
const pageNum = document.getElementById('page_num');
const pageCount = document.getElementById('page_count');
const currentPage = document.getElementById('current_page');
const previousPageBtn = document.getElementById('prev_page_btn');
const nextPageBtn = document.getElementById('next_page_btn');
const zoomLevelText = document.getElementById('zoom_level_text');
const zoomInBtn = document.getElementById('zoom_in_btn');
const zoomOutBtn = document.getElementById('zoom_out_btn');

const docIframe = document.getElementById('doc_renderer');
const docIframeErrorContainer = document.getElementById('doc_renderer_error');

const initialState = {
  pdfDoc: null,
  currentPage: 1,
  pageCount: 0,
  zoom: 1,
};

const MAX_ZOOM = 5.0;
const MIN_ZOOM = 0.5;

if (pdfUrl) {
  const loadingTask = getDocument(pdfUrl);
  loadingTask.promise
    .then((data) => {
      initialState.pdfDoc = data;
      pageCount.textContent = initialState.pdfDoc._pdfInfo.numPages;

      renderPage();
    })
    .catch((error) => {
      alert(error.message);
    });
}

const renderPage = () => {
  // Load the first page.
  initialState.pdfDoc.getPage(initialState.currentPage).then((page) => {
    const ctx = canvas.getContext('2d');
    const viewport = page.getViewport({
      scale: 1.5,
    });

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // Render the PDF page into the canvas context.
    const renderCtx = {
      canvasContext: ctx,
      viewport: viewport,
    };

    page.render(renderCtx);

    pageNum.textContent = initialState.currentPage;
    if (container) {
      container.style.width = `${viewport.width * initialState.zoom}px`;
      container.style.height = `${viewport.height * initialState.zoom}px`;
    }
    zoomLevelText.textContent = `${Math.round(initialState.zoom * 100)}%`;
    zoomInBtn.disabled = initialState.zoom >= MAX_ZOOM;
    zoomOutBtn.disabled = initialState.zoom <= MIN_ZOOM;
  });
};

const showPrevPage = () => {
  if (initialState.pdfDoc === null || initialState.currentPage <= 1) return;
  initialState.currentPage--;
  // Render the current page.
  currentPage.value = initialState.currentPage;
  renderPage();
};

const showNextPage = () => {
  if (
    initialState.pdfDoc === null ||
    initialState.currentPage >= initialState.pdfDoc._pdfInfo.numPages
  )
    return;

  initialState.currentPage++;
  currentPage.value = initialState.currentPage;
  renderPage();
};

previousPageBtn?.addEventListener('click', showPrevPage);
nextPageBtn?.addEventListener('click', showNextPage);

currentPage?.addEventListener('keypress', (event) => {
  if (initialState.pdfDoc === null) return;
  // Get the key code.
  const keycode = event.keyCode ? event.keyCode : event.which;

  if (keycode === 13) {
    // Get the new page number and render it.
    let desiredPage = currentPage.valueAsNumber;
    initialState.currentPage = Math.min(
      Math.max(desiredPage, 1),
      initialState.pdfDoc._pdfInfo.numPages,
    );

    currentPage.value = initialState.currentPage;
    renderPage();
  }
});

function zoomFunction(number) {
  if (initialState.pdfDoc === null) return;
  const newZoom = initialState.zoom + number;
  if (newZoom >= MIN_ZOOM && newZoom <= MAX_ZOOM) {
    initialState.zoom = newZoom;
    canvas.style.transform = `scale(${initialState.zoom})`;
    renderPage();
  }
}

zoomInBtn?.addEventListener('click', () => zoomFunction(0.25));

zoomOutBtn?.addEventListener('click', () => zoomFunction(-0.25));

document.addEventListener('keydown', (event) => {
  if (event.ctrlKey) {
    if (event.key === '+') {
      // Ctrl + Zoom In
      if (initialState.zoom < MAX_ZOOM) {
        initialState.zoom += 0.25;
        renderPage();
      }
      event.preventDefault();
    } else if (event.key === '-') {
      // Ctrl + Zoom Out
      if (initialState.zoom > MIN_ZOOM) {
        initialState.zoom -= 0.25;
        renderPage();
      }
      event.preventDefault();
    }
  }
});

// for rendering ppt and docs
try {
  if (!docIframe) {
    throw new Error('Document container not found.');
  }

  const docLink = docIframe.dataset.link;
  if (!docLink) {
    throw new Error('The document link is missing or invalid.');
  }

  let newLink;
  try {
    newLink = new URL(docLink);
  } catch (error) {
    throw new Error('The document link is missing or invalid.');
  }

  const finalLink = `${newLink.origin}${newLink.pathname}`;
  const officeEmbedUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${finalLink}`;

  if (docIframe) docIframe.src = officeEmbedUrl;
} catch (error) {
  if (docIframe) docIframe.style.display = 'none';
  if (docIframeErrorContainer) {
    docIframeErrorContainer.classList.remove('hidden');
    docIframeErrorContainer.innerHTML = `
      <h3 class="text-red-400 font-bold text-lg mb-2">An Error Occurred</h3>
      <p class="text-[#444444] mb-2">${error.message}</p>
    `;
  }
}
