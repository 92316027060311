document.addEventListener('DOMContentLoaded', function () {
  const contractId = document.querySelector('[name=contractid]')?.value;
  const containerMilestone = document.getElementById('milestone-container');
  const refreshBtn = document.getElementById('milestone-refresh-button');

  // populating/fetching milestone
  if (contractId && containerMilestone) {
    populateMilestones();
  }

  refreshBtn?.addEventListener('click', populateMilestones);

  function calculateProgress(tasks) {
    if (tasks.length === 0) return 0;
    const completed = tasks.filter((task) => task.is_completed).length;
    return Math.round((completed / tasks.length) * 100);
  }

  function getProgressColor(progress) {
    if (progress >= 90) return '#04B000';
    if (progress >= 50) return '#FFB629';
    return '#FF0000';
  }

  function formatDate(dateString) {
    return new Date(dateString).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  }

  function createMilestone(data) {
    const progress = calculateProgress(data.tasks);
    const progressColor = getProgressColor(progress);

    const accordionDiv = document.createElement('div');
    accordionDiv.className =
      'rounded-md shadow-md p-3 md:pr-4 flex gap-4 bg-white closed';
    accordionDiv.setAttribute('data-accordion', '');

    const mainContent = document.createElement('div');
    mainContent.className = 'w-full flex flex-col';

    const headerDiv = document.createElement('div');
    headerDiv.className =
      'flex justify-between gap-4 md:gap-6 lg:gap-10 items-center';

    const triggerDiv = document.createElement('div');
    triggerDiv.className =
      'flex gap-4 items-center w-full text-neutral-700 cursor-pointer';
    triggerDiv.setAttribute('data-accordion-trigger', '');

    triggerDiv.addEventListener('click', () => {
      accordionDiv.classList.toggle('closed');
    });

    const contentDiv = document.createElement('div');
    contentDiv.className = 'w-full flex flex-col gap-1 text-sm';

    const titleDiv = document.createElement('div');
    titleDiv.className = 'flex justify-between';
    titleDiv.innerHTML = `
        <p class="font-[500]">${data.name}</p>
        <p>${progress}%</p>
      `;

    const progressBar = document.createElement('div');
    progressBar.className =
      'rounded-md overflow-hidden flex justify-start h-1 bg-neutral-400';
    progressBar.innerHTML = `<div class="h-full" style="width: ${progress}%; background-color: ${progressColor}"></div>`;

    const deadlineP = document.createElement('p');
    deadlineP.innerHTML = `
        <span class="text-red-600 font-[500]">Deadline: </span>
        <span>${formatDate(data.deadline)}</span>
      `;

    const chevronIcon = document.createElement('i');
    chevronIcon.className = 'flex-shrink-0 transition-all duration-200 text-sm';
    chevronIcon.setAttribute('data-accordion-chevron', '');
    chevronIcon.innerHTML = `<svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.6668 1.33333L8.00016 8L1.3335 1.33333" stroke="#242424" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`;

    const actionsDiv = document.createElement('div');
    actionsDiv.className =
      'flex-shrink-0 flex gap-2 md:gap-4 items-center text-sm md:text-xl';

    if (data.can_edit) {
      const editButton = document.createElement('button');
      editButton.className =
        'rounded-md px-2 py-1 hover:bg-neutral-100 text-neutral-500';
      editButton.innerHTML = `<svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.5 4.00004L22 8.50004M14.5 25H26.5M2.5 19L1 25L7 23.5L24.379 6.12104C24.9414 5.55845 25.2574 4.79553 25.2574 4.00004C25.2574 3.20454 24.9414 2.44162 24.379 1.87904L24.121 1.62104C23.5584 1.05862 22.7955 0.742676 22 0.742676C21.2045 0.742676 20.4416 1.05862 19.879 1.62104L2.5 19Z" stroke="#242424" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`;
      actionsDiv.appendChild(editButton);
      editButton.addEventListener('click', (e) =>
        handleEditMileStoneModalOpen(data),
      );
    }

    if (data.can_delete) {
      const deleteButton = document.createElement('button');
      deleteButton.className =
        'rounded-md px-2 py-1 hover:bg-neutral-100 text-red-500';
      deleteButton.innerHTML = `<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.6002 4.40002H15.4002C15.4002 3.7635 15.1473 3.15306 14.6973 2.70297C14.2472 2.25288 13.6367 2.00002 13.0002 2.00002C12.3637 2.00002 11.7532 2.25288 11.3031 2.70297C10.8531 3.15306 10.6002 3.7635 10.6002 4.40002ZM9.00019 4.40002C9.00019 3.33916 9.42162 2.32174 10.1718 1.5716C10.9219 0.821452 11.9393 0.400024 13.0002 0.400024C14.0611 0.400024 15.0785 0.821452 15.8286 1.5716C16.5788 2.32174 17.0002 3.33916 17.0002 4.40002H25.0002C25.2124 4.40002 25.4159 4.48431 25.5659 4.63434C25.7159 4.78437 25.8002 4.98785 25.8002 5.20002C25.8002 5.4122 25.7159 5.61568 25.5659 5.76571C25.4159 5.91574 25.2124 6.00002 25.0002 6.00002H23.3138L21.4034 22.5504C21.2684 23.7203 20.7079 24.7998 19.8288 25.5833C18.9496 26.3669 17.8131 26.7999 16.6354 26.8H9.365C8.18732 26.7999 7.05078 26.3669 6.17161 25.5833C5.29245 24.7998 4.73204 23.7203 4.597 22.5504L2.68659 6.00002H1.0002C0.788022 6.00002 0.584539 5.91574 0.43451 5.76571C0.284481 5.61568 0.200195 5.4122 0.200195 5.20002C0.200195 4.98785 0.284481 4.78437 0.43451 4.63434C0.584539 4.48431 0.788022 4.40002 1.0002 4.40002H9.00019ZM11.4002 10.8C11.4002 10.5879 11.3159 10.3844 11.1659 10.2343C11.0159 10.0843 10.8124 10 10.6002 10C10.388 10 10.1845 10.0843 10.0345 10.2343C9.88448 10.3844 9.80019 10.5879 9.80019 10.8V20.4C9.80019 20.6122 9.88448 20.8157 10.0345 20.9657C10.1845 21.1157 10.388 21.2 10.6002 21.2C10.8124 21.2 11.0159 21.1157 11.1659 20.9657C11.3159 20.8157 11.4002 20.6122 11.4002 20.4V10.8ZM15.4002 10C15.188 10 14.9845 10.0843 14.8345 10.2343C14.6845 10.3844 14.6002 10.5879 14.6002 10.8V20.4C14.6002 20.6122 14.6845 20.8157 14.8345 20.9657C14.9845 21.1157 15.188 21.2 15.4002 21.2C15.6124 21.2 15.8159 21.1157 15.9659 20.9657C16.1159 20.8157 16.2002 20.6122 16.2002 20.4V10.8C16.2002 10.5879 16.1159 10.3844 15.9659 10.2343C15.8159 10.0843 15.6124 10 15.4002 10Z" fill="#FF2929"/>
        </svg>`;
      actionsDiv.appendChild(deleteButton);
      deleteButton.addEventListener('click', () => handleDelete(data.id));
    }

    const tasksDiv = document.createElement('div');
    tasksDiv.className = 'flex flex-col gap-2 pt-6';
    tasksDiv.setAttribute('data-accordion-body', '');

    data.tasks.forEach((task) => {
      const taskDiv = document.createElement('div');
      taskDiv.className = 'flex items-center gap-2 md:gap-4';

      const checkbox = document.createElement('input');
      checkbox.type = 'checkbox';
      checkbox.className = 'flex-shrink-0 bg-gray-200 cursor-not-allowed';
      checkbox.checked = task.is_completed;
      checkbox.disabled = true;

      const label = document.createElement('label');
      label.className = 'w-full text-sm text-neutral-700';
      label.textContent = task.description;

      taskDiv.appendChild(checkbox);
      taskDiv.appendChild(label);
      tasksDiv.appendChild(taskDiv);
    });

    contentDiv.appendChild(titleDiv);
    contentDiv.appendChild(progressBar);
    contentDiv.appendChild(deadlineP);

    triggerDiv.appendChild(contentDiv);
    triggerDiv.appendChild(chevronIcon);

    headerDiv.appendChild(triggerDiv);
    headerDiv.appendChild(actionsDiv);

    mainContent.appendChild(headerDiv);
    mainContent.appendChild(tasksDiv);

    accordionDiv.appendChild(mainContent);

    return accordionDiv;
  }

  function populateMilestones() {
    const container = document.getElementById('milestone-container');
    container.innerHTML = `<div class="flex items-center justify-center py-8" id="preloader">
        <div class="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-[#670FF1]"></div>
      </div>`;

    fetch(`/api/contract/milestone/?contract_id=${contractId}`)
      .then((response) => response.json())
      .then((data) => {
        container.innerHTML = '';
        if (data.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: data.error || 'An error occurred while fetching milestones',
          });
          return;
        }
        if (data.length === 0) {
          container.innerHTML = `<div class="flex items-center justify-center py-8">
            <p class="text-gray-500">No milestones found</p>
          </div>`;
          return;
        }
        renderMilestones(data);
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to fetch milestones. Please try again later.',
        });
      });
  }

  function renderMilestones(data) {
    const container = document.getElementById('milestone-container');
    container.innerHTML = '';
    data
      .sort((a, b) => new Date(a.deadline) - new Date(b.deadline))
      .forEach((milestone) => {
        container.appendChild(createMilestone(milestone));
      });
  }

  // edit milestone
  let isEdited = false;
  let editModalCloseBtn = document.getElementById('close-edit-milestone-modal');

  function createMilestoneEditContent(data) {
    const content = document.getElementById('milestone-edit-content');
    content.innerHTML = '';

    // Heading input
    const headingDiv = document.createElement('div');
    const headingLabel = document.createElement('label');
    headingLabel.setAttribute('for', 'milestone-edit-heading');
    headingLabel.className = 'font-[500] text-neutral-700';
    headingLabel.textContent = 'Milestone Heading ';
    const required = document.createElement('i');
    required.className = 'text-red-500';
    required.textContent = '*';
    headingLabel.appendChild(required);

    const headingInput = document.createElement('input');
    headingInput.type = 'text';
    headingInput.id = 'milestone-edit-heading';
    headingInput.className = 'w-full py-2 px-4 mt-2 border rounded-md';
    headingInput.value = data.name;
    headingInput.addEventListener('input', (e) => {
      isEdited = e.target.value !== data.name;
    });

    headingDiv.appendChild(headingLabel);
    headingDiv.appendChild(headingInput);

    // Deadline input
    const deadlineDiv = document.createElement('div');
    const deadlineLabel = document.createElement('label');
    deadlineLabel.setAttribute('for', 'milestone-edit-deadline');
    deadlineLabel.className = 'font-[500] text-neutral-700';
    deadlineLabel.textContent = 'Deadline ';
    deadlineLabel.appendChild(required.cloneNode(true));

    const deadlineInput = document.createElement('input');
    deadlineInput.type = 'text';
    deadlineInput.id = 'milestone-edit-deadline';
    deadlineInput.className =
      'w-full py-2 px-4 mt-2 border rounded-md flatpickr-input';
    deadlineInput.placeholder = 'Select deadline';
    deadlineInput.value = data.deadline;

    deadlineDiv.appendChild(deadlineLabel);
    deadlineDiv.appendChild(deadlineInput);

    // Tasks section
    const tasksDiv = document.createElement('div');
    tasksDiv.className = 'mt-4 flex flex-col gap-2';

    const tasksHeading = document.createElement('h5');
    tasksHeading.className = 'font-[500] text-neutral-700';
    tasksHeading.textContent = 'Tasks under milestone ';
    tasksHeading.appendChild(required.cloneNode(true));

    const tasksContainer = document.createElement('div');
    tasksContainer.id = 'tasks-container';
    tasksContainer.className = 'flex flex-col gap-2';

    function createTaskElement(
      taskText = '',
      isChecked = false,
      originalTask = null,
      index = null,
    ) {
      const taskDiv = document.createElement('div');
      taskDiv.className = 'flex gap-2 items-center text-neutral-700';

      const dragHandle = document.createElement('div');
      dragHandle.className = 'cursor-move px-2';
      dragHandle.textContent = '⋮';

      const checkbox = document.createElement('input');
      checkbox.type = 'checkbox';
      checkbox.className = 'flex-shrink-0';
      checkbox.checked = isChecked;
      checkbox.addEventListener('change', () => {
        checkForChanges();
      });

      const taskInput = document.createElement('input');
      taskInput.type = 'text';
      taskInput.className =
        'p-1 focus:outline-1 focus:outline-neutral-700 w-full';
      taskInput.value = taskText;
      taskInput.addEventListener('input', () => {
        checkForChanges();
      });

      const deleteBtn = document.createElement('button');
      deleteBtn.className = 'text-red-500 hover:text-red-700 px-2';
      deleteBtn.textContent = '×';
      deleteBtn.onclick = () => {
        taskDiv.remove();
        checkForChanges();
      };

      taskDiv.appendChild(dragHandle);
      taskDiv.appendChild(checkbox);
      taskDiv.appendChild(taskInput);
      taskDiv.appendChild(deleteBtn);

      if (originalTask) {
        taskDiv.dataset.originalText = originalTask.description;
        taskDiv.dataset.originalStatus = originalTask.is_completed;
        taskDiv.dataset.originalIndex = index;
      }

      return taskDiv;
    }

    function checkForChanges() {
      const currentHeading = document.getElementById(
        'milestone-edit-heading',
      ).value;
      const currentDeadline = document.getElementById(
        'milestone-edit-deadline',
      ).value;
      const headingChanged = currentHeading !== data.name;
      const deadlineChanged = currentDeadline !== data.deadline;

      const currentTasks = Array.from(tasksContainer.children);
      const tasksChanged =
        currentTasks.length !== data.tasks.length ||
        currentTasks.some((taskDiv, currentIndex) => {
          if (!taskDiv.dataset.originalText) return true; // New task added
          const taskInput = taskDiv.querySelector('input[type="text"]');
          const checkbox = taskDiv.querySelector('input[type="checkbox"]');
          const originalIndex = parseInt(taskDiv.dataset.originalIndex);

          return (
            taskInput.value !== taskDiv.dataset.originalText ||
            checkbox.checked.toString() !== taskDiv.dataset.originalStatus ||
            currentIndex !== originalIndex
          );
        });

      isEdited = headingChanged || deadlineChanged || tasksChanged;
    }

    data.tasks.forEach((task, index) => {
      tasksContainer.appendChild(
        createTaskElement(task.description, task.is_completed, task, index),
      );
    });

    // Initialize Sortable for tasks
    new Sortable(tasksContainer, {
      animation: 150,
      handle: '.cursor-move',
      onEnd: () => {
        checkForChanges();
      },
    });

    const addTaskBtn = document.createElement('button');
    addTaskBtn.className =
      'rounded-md px-2 py-1 text-[#670FF1] text-sm w-fit bg-neutral-100';
    addTaskBtn.textContent = 'Add new task';
    addTaskBtn.onclick = () => {
      tasksContainer.appendChild(createTaskElement());
      checkForChanges();
    };

    tasksDiv.appendChild(tasksHeading);
    tasksDiv.appendChild(tasksContainer);
    tasksDiv.appendChild(addTaskBtn);

    // Save button
    const saveButtonDiv = document.createElement('div');
    saveButtonDiv.className = 'flex justify-end mt-10';

    const saveButton = document.createElement('button');
    saveButton.className =
      'inline-flex text-white bg-[#670FF1] hover:bg-purple-800 items-center gap-1 px-4 py-2 rounded-md';
    saveButton.innerHTML = '<span>Save Milestone</span>';
    saveButton.onclick = () => handleSave(saveButton, data.id);

    saveButtonDiv.appendChild(saveButton);

    // Append all elements
    content.appendChild(headingDiv);
    content.appendChild(deadlineDiv);
    content.appendChild(tasksDiv);
    content.appendChild(saveButtonDiv);

    // Initialize flatpickr for deadline
    flatpickr('#milestone-edit-deadline', {
      enableTime: false,
      dateFormat: 'Y-m-d',
      minDate: 'today',
      onChange: () => {
        checkForChanges();
      },
    });
  }

  async function handleSave(saveButton, id) {
    let originalText = saveButton.innerHTML;
    saveButton.innerHTML =
      '<div class="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div> saving...';

    const updatedData = {
      heading: document.getElementById('milestone-edit-heading').value,
      deadline: document.getElementById('milestone-edit-deadline').value,
      tasks: Array.from(
        document.getElementById('tasks-container').children,
      ).map((taskDiv) => ({
        text: taskDiv.querySelector('input[type="text"]').value,
        completed: taskDiv.querySelector('input[type="checkbox"]').checked,
      })),
    };

    try {
      const response = await fetch(
        `/api/contract/milestone/update/?milestone_id=${id}&contract_id=${contractId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': document.querySelector('[name=csrfmiddlewaretoken]')
              .value,
          },
          body: JSON.stringify(updatedData),
        },
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to update milestone');
      }

      isEdited = false;
      handleEditMileStoneModalClose();
      renderMilestones(data);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message,
      });
    } finally {
      saveButton.innerHTML = originalText;
    }
  }

  async function handleEditMileStoneModalClose() {
    const editModalcontainer = document.getElementById('edit-milestone-modal');

    if (isEdited) {
      const result = await Swal.fire({
        title: 'Unsaved Changes',
        text: 'You have unsaved changes. Are you sure you want to close?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#670FF1',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, close',
      });

      if (result.isConfirmed) {
        editModalcontainer.classList.replace('flex', 'hidden');
      }
    } else {
      editModalcontainer.classList.replace('flex', 'hidden');
    }
  }

  function handleEditMileStoneModalOpen(data) {
    const editModalcontainer = document.getElementById('edit-milestone-modal');

    editModalcontainer.classList.replace('hidden', 'flex');

    const editModalcontent = document.getElementById('milestone-edit-content');
    editModalcontent.innerHTML = ` <div class="flex justify-center">
        <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-[#670FF1]"></div>
      </div>`;

    setTimeout(() => {
      createMilestoneEditContent(data);
    }, 1000);
  }

  editModalCloseBtn?.addEventListener('click', handleEditMileStoneModalClose);

  // create milestone
  const modal = document.querySelector('[data-modal="milestone-modal"]');
  const closeBtn = document.getElementById('close-milestone-modal');
  const addTaskBtn = document.getElementById('add-task-btn');
  const tasksContainer = document.getElementById('new-tasks');
  const addMilestoneBtn = document.getElementById('add-milestone-btn');
  const headingInput = document.getElementById('milestone-heading');
  const createMilestoneBtn = document.getElementById('create-milestone-btn');
  let hasChanges = false;

  // Initialize flatpickr
  flatpickr('#milestone-deadline', {
    enableTime: false,
    dateFormat: 'Y-m-d',
    minDate: 'today',
  });

  if (tasksContainer)
    // Initialize Sortable
    new Sortable(tasksContainer, {
      animation: 150,
      handle: '.cursor-move',
      onEnd: () => {
        hasChanges = true;
      },
    });

  function resetModal() {
    headingInput.value = '';
    document.getElementById('milestone-deadline').value = '';
    tasksContainer.innerHTML = '';
    addTaskItem();
    hasChanges = false;
  }

  function addTaskItem() {
    // Check if there's already an empty task
    const emptyTasks = Array.from(
      tasksContainer.querySelectorAll('.task-input'),
    ).filter((input) => !input.value.trim());
    if (emptyTasks.length > 0) {
      Swal.fire(
        'Warning',
        'Please fill in the empty task before adding a new one',
        'warning',
      );
      return;
    }

    const taskDiv = document.createElement('div');
    taskDiv.className = 'task-item flex gap-2 items-center text-neutral-700';
    taskDiv.draggable = true;
    taskDiv.innerHTML = `
      <div class="cursor-move px-2">⋮</div>
      <input type="checkbox" class="flex-shrink-0" />
      <input type="text" name="task" class="task-input p-1 focus:outline-1 focus:outline-neutral-700 w-full" placeholder="enter task" />
      <button class="delete-task text-red-500 px-2">×</button>
    `;

    taskDiv.querySelector('.task-input').addEventListener('input', () => {
      hasChanges = true;
    });

    taskDiv.querySelector('.delete-task').addEventListener('click', () => {
      if (tasksContainer.children.length > 1) {
        taskDiv.remove();
        hasChanges = true;
      }
    });

    tasksContainer.appendChild(taskDiv);
  }

  addTaskBtn?.addEventListener('click', addTaskItem);

  headingInput?.addEventListener('input', () => {
    hasChanges = true;
  });

  closeBtn?.addEventListener('click', async () => {
    if (hasChanges) {
      const result = await Swal.fire({
        title: 'Unsaved Changes',
        text: 'You have unsaved changes. Are you sure you want to close?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, close',
        cancelButtonText: 'No, keep editing',
      });

      if (result.isConfirmed) {
        modal.classList.add('hidden');
        resetModal();
      }
    } else {
      modal.classList.add('hidden');
    }
  });

  addMilestoneBtn?.addEventListener('click', async () => {
    // Validate heading
    if (!headingInput.value.trim()) {
      Swal.fire('Error', 'Please enter a milestone heading', 'error');
      return;
    }

    // Validate deadline
    if (!document.getElementById('milestone-deadline').value) {
      Swal.fire('Error', 'Please select a deadline', 'error');
      return;
    }

    // Get all non-empty tasks
    const tasks = Array.from(tasksContainer.querySelectorAll('.task-item'))
      .map((task) => ({
        text: task.querySelector('.task-input').value.trim(),
        completed: task.querySelector('input[type="checkbox"]').checked,
      }))
      .filter((task) => task.text !== '');

    // Validate tasks
    if (tasks.length === 0) {
      Swal.fire('Error', 'Please add at least one task', 'error');
      return;
    }

    const milestoneData = {
      heading: headingInput.value.trim(),
      deadline: document.getElementById('milestone-deadline').value,
      tasks: tasks,
    };

    const loader = addMilestoneBtn.querySelector('.animate-spin');
    const buttonText = addMilestoneBtn.querySelector('span');

    loader.classList.remove('hidden');
    buttonText.textContent = 'Adding...';
    addMilestoneBtn.disabled = true;

    try {
      const response = await fetch(
        `/api/contract/milestone/create/?contract_id=${contractId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': document.querySelector('[name=csrfmiddlewaretoken]')
              .value,
          },
          body: JSON.stringify(milestoneData),
        },
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Something went wrong');
      }

      renderMilestones(data);
      await Swal.fire('Success', 'Milestone added successfully!', 'success');
      modal.classList.add('hidden');
      resetModal();
    } catch (error) {
      await Swal.fire(
        'Error',
        error.message || 'An error occurred while saving the milestone',
        'error',
      );
    } finally {
      loader.classList.add('hidden');
      buttonText.textContent = 'Add Milestone';
      addMilestoneBtn.disabled = false;
    }
  });

  createMilestoneBtn?.addEventListener('click', () => {
    const createMilestoneContainer = document.getElementById(
      'create-milestone-modal',
    );
    resetModal();
    createMilestoneContainer.classList.replace('hidden', 'flex');
  });

  // delete milestone
  function handleDelete(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(
            `/api/contract/milestone/delete/?contract_id=${contractId}&milestone_id=${id}`,
            {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': document.querySelector(
                  '[name=csrfmiddlewaretoken]',
                ).value,
              },
            },
          );

          const data = await response.json();

          if (!response.ok) {
            throw new Error(data.error || 'Something went wrong');
          }

          renderMilestones(data);
          await Swal.fire('Deleted!', 'Milestone has been deleted.', 'success');
        } catch (error) {
          await Swal.fire(
            'Error',
            error.message || 'An error occurred while deleting the milestone',
            'error',
          );
        }
      }
    });
  }
});
